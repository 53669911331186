import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer";
import Container from "@material-ui/core/Container";

const HowToCreditOnDelivery = () => {
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    const visa = 'images/card_visa.png'
    const master = 'images/card_master.gif'
    const unionPay = 'images/card_union_pay.png'
    const jcb = 'images/card_jcb.svg.png'
    const amex = 'images/card_amex.png'
    const diners = 'images/card_diners.png'
    const discover = 'images/card_discover.jpg'
    const airPay = 'images/air_pay.png'
    // const aliPay = 'images/qr_ali.png'
    // const qrAll = 'images/qr.jpg'
    // const auPay = 'images/qr_au.png'
    // const linePay = 'images/qr_line.png'
    // const unionPayQr = 'images/qr_union.jpeg'
    // const weChatPay = 'images/qr_we_chat.png'
    const quickPay = 'images/quick_pay.png'
    const qrLeft = 'images/qr_left.jpg'
    const qrRight = 'images/qr_right.jpg'
    const qrDPay = 'images/qr_d_pay.png'
    const qrJcoin = 'images/qr_jcoin.png'
    const qrPayPay = 'images/paypay.png'

    return (
        <div className="pageWrapper">
            <NavBar/>

            <Container maxWidth='xs'>
                <h3 style={{textAlign: 'center'}}>
                    {isEnglish ? 'What is Credit on delivery？' : '配達時クレジット払いとは？'}
                </h3>

                <div style={{whiteSpace: 'pre-wrap'}}>
                    {isEnglish ?
                        'This is a payment method by credit card or touch / QR payment when the delivery person hands over the ordered item\n\n'
                        :
                        'ご注文いただいた商品を配達員がお渡しする際に、クレジットカードまたはタッチ / QR決済で代金をお支払いただく方法です。\n\n'
                    }
                    <span style={{color: 'red'}}>
                        {isEnglish ?
                            '※Please note that it may not work in places with weak signals.\n' +
                            'If we are unable to do so, we will change the payment method to cash on delivery. Please understand.'
                            :
                            '※電波が弱い所では対応できない場合がございますのでご注意ください。\n' +
                            'また対応できない場合は、お支払い方法を代金引換に変更させていただきます。ご了承ください。'
                        }
                    </span>
                </div>

                <div style={{textAlign: 'center'}}>
                    <p style={{color: '#ff5722', marginTop: '1.5rem', marginBottom: '1.5rem', fontWeight: 'bold'}}>
                        {isEnglish ? '＜Accepted Brands＞' : '＜ご利用いただけるブランド＞'}
                    </p>
                    <div style={{display: 'flex', justifyContent: 'space-around', width: '100%'}}>
                        <img src={visa} alt="visa" width='40px' height='25.2px'/>
                        <img src={master} alt="master" width='40px' height='25.2px'/>
                        <img src={unionPay} alt="master" width='40px' height='25.2px'/>
                        <img src={jcb} alt="jcb" width='40px' height='25.2px'/>
                        <img src={amex} alt="amex" width='40px' height='25.2px'/>
                        <img src={diners} alt="diners" width='40px' height='25.2px'/>
                        <img src={discover} alt="discover" width='40px' height='25.2px'/>
                    </div>


                    <p style={{color: '#ff5722', marginTop: '1.5rem', marginBottom: '1rem', fontWeight: 'bold'}}>
                        {isEnglish ? '＜Accepted touch / QR payment＞' : '＜ご利用いただけるタッチ / QR決済＞'}
                    </p>

                    {/*<div>*/}
                    {/*    <img src={qrLeft} alt="qr" width='50%'/>*/}
                    {/*    <img src={qrRight} alt="qr" width='50%'/>*/}

                    {/*</div>*/}
                    <div style={{display: 'flex', width: '100%', justifyContent: "space-evenly"}}>
                        <img src={quickPay} alt="quick" width='60px' height='30px' style={{marginRight: '4px'}}/>
                        {/*<img src={qrDPay} alt="quick" width='60px' height='30px' style={{marginRight: '4px'}}/>*/}
                        {/*<img src={qrJcoin} alt="quick" width='60px' height='30px' style={{marginRight: '4px'}}/>*/}
                        <img src={qrPayPay} alt="quick" width='60px' height='30px' style={{marginRight: '4px'}}/>
                    </div>

                    <img src={airPay} alt="airPayImage" width='100%' style={{marginTop: '2rem', marginBottom: '2rem'}}/>

                </div>

            </Container>
            {/*80:50.4 = 100: 63*/}
            {/*60: 37.8 = 100: 63*/}

            <Footer/>
        </div>
    )
}

export default HowToCreditOnDelivery