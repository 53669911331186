import React, {createContext, useEffect, useMemo, useState} from "react"
import axios from "axios"
import {COSTCO_CATEGORY_URL, COSTCO_ORDER_URL, COSTCO_PRODUCT_URL, MY_PROFILE_URL} from "../../Messages/urls"
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import Badge from '@material-ui/core/Badge';
import CostcoCart from "./CostcoCart";
import Products from "./Products";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CostcoCheckout from "./CostcoCheckout/CostcoCheckout";
import {withCookies} from "react-cookie";
import Grid from "@material-ui/core/Grid";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CostcoConfirm from "./CostcoConfirm";
import CartLink from "./CartLink";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from "@material-ui/icons/Close";

export const CostcoContext = createContext()

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
        fontFamily: 'American Typewriter',
        fontWeight: 'bolder'
    },
    titleImg: {
        marginTop: theme.spacing(2),
        width: '50%',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
    },
    headerFixed: {
        position: 'sticky',
        top: '0',
        backgroundColor: '#ffffff',
        zIndex: '1',
        // borderBottom: 'solid #afeeee',
        borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
    },
    header: {
        // position: 'sticky',
        // top: '0',
        backgroundColor: '#ffffff',
        // zIndex: '1',
        // borderBottom: 'solid #afeeee'
        borderBottom: '1px solid rgba(0, 0, 0, 0.54)'
    },
    cartIcon: {
        // float: 'right'
        paddingBottom: '0',
        marginBottom: '8px',
        marginRight: '8px',
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        // paddingTop: '56.25%', // 16:9
        paddingTop: '70%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
        [theme.breakpoints.only('xs')]: {
            padding: '6px 8px 2px',
        },
    },
    checkOut: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    formControl: {
        marginLeft: theme.spacing(3),
        minWidth: 100,
    },
    marginTheme: {
        margin: theme.spacing(1)
    },
    pagination: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        textAlign: 'center',
    },
    pageNaviCurrent: {
        cursor: 'default',
    },
    pageNaviText: {
        fontSize: '20px',
        // スマホサイズ
        [theme.breakpoints.only('xs')]: {
            fontSize: '15px',
        },
    },
    pageNaviStandard: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
        }
    },
    pageNaviArrow: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)'
        }
    },
    modalPaper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    table: {
        minWidth: 650,
    },
    checkOutContainer: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
    },
    checkoutButton: {
        borderRadius: "10%",
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    categoryList: {
        marginRight: theme.spacing(3)
    },
    categoryDrawer: {
        float: "left",
        margin: 0,
    },
    back: {
        paddingBottom: '0',
        marginBottom: '0'
    },
    place: {
        // paddingTop: '5px',
        color: 'red',
        marginLeft: theme.spacing(1),
        fontSize: '30px'
    },
    svg: {
        width: '100%',
        textAlign: 'center'
    },
    placeSub: {
        fontSize: '12px'
    },
    background: {
        stroke: '#000',
        strokeWidth: '4',
        strokeLinejoin: 'round',
        fontFamily: 'American Typewriter',
    },
    foreground: {
        fill: '#afeeee',
        fontFamily: 'American Typewriter',
    },
    dialogTitle: {
        margin: theme.spacing(1),
    },
    closeButton: {
        position: 'absolute',
        // right: theme.spacing(1),
        // top: '7px',
        right: '13px',
        top: '13px',
        color: 'black',
        zIndex: 999,
    },
    titleWrapper: {
        // backgroundColor: '#afeeee',
        color: '#ff5722',
        // textAlign: 'center',
        // width: '275px'
        paddingLeft: '2rem',
        paddingBottom: '1.2rem',
    },
    orangeTitle: {
        color: '#ea5506',
        marginBottom: 0,
        marginTop: '1rem',
    },
    dialogImage: {
        marginTop: '30px !important',
        marginBottom: '30px !important',
        textAlign: 'center',
        height: '120px',
    },
    selectForm: {
        width: '100%',
        "& .MuiFormLabel-root.Mui-error": {
            color: "#ff5722 !important",
        },
        "& .MuiOutlinedInput-root.Mui-error": {
            '& fieldset': {
                borderColor: '#ff5722',
            },
        },
    },
    searchField: {
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
        },
        '& ::placeholder': {
            color: 'black',
            opacity: 0.6,
        }
    }
}))


const CostcoHome = (props) => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'

    const token = props.cookies.get('current-token')
    const [myProfile, setMyProfile] = useState([])
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])

    const logoutIcon = 'images/logout_icon.png'

    // localStorage
    const appState = localStorage.getItem('app_key')

    const [cart, setCart] = useState([])
    const [filterQuery, setFilterQuery] = useState({})
    const [sort, setSort] = useState({name: 'default'})
    const [japaneseSearchQuery, setJapaneseSearchQuery] = useState()
    const [englishSearchQuery, setEnglishSearchQuery] = useState()
    const [activeStep, setActiveStep] = useState(0);
    const [paymentDetail, setPaymentDetail] = useState([])
    const [offset, setOffset] = useState(0)
    const [shoppingFee, setShoppingFee] = useState('')
    const [deliveryFee, setDeliveryFee] = useState('')
    const [memo, setMemo] = useState('')
    const [cancel, setCancel] = useState(1)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [deliveryDay, setDeliveryDay] = useState('')
    const [orderNumber, setOrderNumber] = useState('')
    const [tip, setTip] = useState(0)
    const [couponPrice, setCouponPrice] = useState(0)
    const [couponCode, setCouponCode] = useState(0)
    const [isCouponApplied, setIsCouponApplied] = useState(false)
    const [email, setEmail] = useState()
    const [activateCart, setActivateCart] = useState(false)
    const [loginDialogOpen, setLoginDialogOpen] = useState(false)
    const [isFirstTime, setIsFirstTime] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // const isNoFee = true
    const isNoFee = false


    const urlParam = window.location.href
    const isConfirm = urlParam.length > 100

    const isCartLink = urlParam.slice(-6) === 'cart_j' || urlParam.slice(-6) === 'cart_e'

    // タイトル画像のパス
    const main_title = 'images/costco_main_title.jpeg'
    const cart_title_eng = 'images/costco_cart_title_eng.jpeg'
    const cart_title_jp = 'images/costco_cart_title_jp.jpeg'
    const co_title_eng = 'images/costco_co_title_eng.jpeg'
    const co_title_jp = 'images/costco_co_title_jp.jpeg'
    const confirm_title_jp = 'images/title_confirm_jp.jpeg'
    const confirm_title_eng = 'images/title_confirm_eng.jpeg'
    const thanks_title_jp = 'images/title_thanks_jp.jpeg'
    const thanks_title_eng = 'images/title_thanks_eng.jpeg'
    const ordering_title_jp = 'images/title_ordering_jp.jpeg'
    const ordering_title_eng = 'images/title_ordering_eng.jpeg'
    const order_done_title_jp = 'images/title_order_done_jp.jpeg'
    const order_done_title_eng = 'images/title_order_done_eng.jpeg'

    const appValue = {
        "cart": cart,
        "cancel": cancel,
        "memo": memo,
        "myProfile": myProfile,
        "deliveryDay": deliveryDay,
        "tip": tip,
        "couponPrice": couponPrice,
        "couponCode": couponCode,
        "isFirstTime": isFirstTime,
    }


    const calculateCartLength = () => {
        const dict = {}
        for (const product of cart) {
            const id = product.id
            if (dict[id]) {
                dict[id] += 1
            } else {
                dict[id] = 1
            }
        }
        return cart.length
        // return Object.keys(dict).length
    }

    const tenPercentOff = price => {
        // 0.9倍して1の位切り捨て
        return Math.floor(price * 0.9 * 0.1) * 10
    }

    // useEffect(() => {
    //    if (products) {
    //        for (let product of products) {
    //            product.marked_up_price = tenPercentOff(product.marked_up_price)
    //        }
    //    }
    // },[products])

    const convertStrToNumber = () => {
        // Productsのpriceをstrからnumberに変換
        for (const product of products) {
            product.price = Number(product.price)
            product.marked_up_price = Number(product.marked_up_price)
        }
    }
    convertStrToNumber()

    // カート内合計
    // const totalAmount = cart.reduce((price, product) => price + product.profit, 0)
    const totalAmount = cart.reduce((price, product) => price + product.marked_up_price, 0)

    const filteredProduct = useMemo(() => {
        let tmpProducts = products
        // 絞り込み検索
        tmpProducts = tmpProducts.filter(row => {
            // 日本語で絞り込み
            if (japaneseSearchQuery && !row.name.includes(japaneseSearchQuery)) {
                return false
            }

            // 英語で絞り込み
            if (englishSearchQuery && !row.english_name.toLowerCase().includes(englishSearchQuery.toLowerCase())) {
                return false
            }

            // カテゴリーで絞り込み
            if (filterQuery.category &&
                row.category !== Number(filterQuery.category)) {
                return false
            }
            return row
        })
        // ソート
        if (sort.key) {
            tmpProducts = tmpProducts.sort((a, b) => {
                if (a.active !== b.active) {
                    // 文字列は大小で比較する必要がある
                    if (a.active > b.active) return -1
                    if (a.active < b.active) return 1
                }
                a = a[sort.key];
                b = b[sort.key];
                return (a === b ? 0 : a > b ? 1 : -1) * sort.order;
            });
        }

        return tmpProducts
    }, [filterQuery, sort, products, japaneseSearchQuery, englishSearchQuery])

    useEffect(() => {
        // if (!token) {
        //     window.location.href = isEnglish ? 'login_costco_e' : 'login_costco'
        // }
        // localStorageの情報をロード
        if (appState) {
            const json = JSON.parse(appState)
            setCart(json.cart)
            setCancel(json.cancel)
            setTip(json.tip)
            setMemo(json.memo)
            setMyProfile(json.myProfile)
            setDeliveryDay(json.deliveryDay)
            setCouponPrice(json.couponPrice)
            setCouponCode(json.couponCode)
            setIsFirstTime(json.isFirstTime)
        }
        // Profileを取得
        axios.get(MY_PROFILE_URL, {headers: {'Authorization': `Token ${token}`}})
            .then(res => {
                setMyProfile(res.data[0])
            })

        // Categoryを取得
        axios.get(COSTCO_CATEGORY_URL)
            .then(res => {
                setCategories(res.data)
            })
        // Productを取得
        axios.get(`${COSTCO_PRODUCT_URL}?english_etc=display`)
            .then(res => {
                setProducts(res.data)
            })
        // confirmに飛ばす
        if (isConfirm) {
            setActiveStep(10)
        }
    }, [token])

    // localStorageにcartの情報を保存
    useEffect(() => {
        if (localStorage.getItem('reset10off_20240930')) {
            localStorage.setItem('app_key', JSON.stringify(appValue))
        } else {
            setCart([])
            localStorage.setItem('reset10off_20240930', 'reseted')
        }
        // localStorage.setItem('app_key', JSON.stringify(appValue))
    }, [appValue])

    const [prevTotalAmount, setPrevTotalAmount] = useState(0)
    useEffect(() => {
        if (prevTotalAmount !== totalAmount && totalAmount) {
            if (prevTotalAmount < totalAmount) {
                countUpAnimation(prevTotalAmount, totalAmount)
            } else {
                countDownAnimation(prevTotalAmount, totalAmount)
            }
        }
        setPrevTotalAmount(totalAmount)
    }, [totalAmount, prevTotalAmount, activeStep])

    // 金額が上がるときのアニメーション
    const countUpAnimation = (fromNum, toNum) => {
        let totalAmount = document.getElementById('totalAmount')
        if (fromNum !== toNum) {
            const CALC_TIMES = 30
            const SPEED = 30
            let reduce = Math.floor((toNum - fromNum) / CALC_TIMES)
            let timer = setInterval(function () {
                if (toNum > (fromNum + reduce)) {
                    fromNum += reduce
                    totalAmount.textContent = fromNum
                } else {
                    totalAmount.textContent = toNum
                    clearInterval(timer)
                }
            }, SPEED)
        }
    }

    // 金額が下がるときのアニメーション
    const countDownAnimation = (fromNum, toNum) => {
        let totalAmount = document.getElementById('totalAmount')
        if (fromNum !== toNum) {
            const CALC_TIMES = 30
            const SPEED = 30
            let reduce = Math.floor((fromNum - toNum) / CALC_TIMES)
            let timer = setInterval(function () {
                if (fromNum > (toNum + reduce)) {
                    fromNum -= reduce
                    totalAmount.textContent = fromNum
                } else {
                    totalAmount.textContent = toNum
                    clearInterval(timer)
                }
            }, SPEED)
        }
    }

    const activateCartButton = () => {
        setActivateCart(true)
    }

    // ３秒間はカート押せないように設定する
    setTimeout(activateCartButton, 3000)

    const handleClose = () => {
        setLoginDialogOpen(false);
    };

    const cartIconClick = () => {
        if (!token) {
            setLoginDialogOpen(true);
        } else {
            setActiveStep(activeStep + 1);
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }

    useEffect(() => {
        if (myProfile) {
            axios.get(`${COSTCO_ORDER_URL}?user=${myProfile.user_profile}`)
                .then(res => {
                    setIsFirstTime(res.data.length === 0)
                })
        }
    })

    return (
        <CostcoContext.Provider
            value={{
                classes,
                categories,
                filterQuery,
                setFilterQuery,
                cart,
                setCart,
                filteredProduct,
                products,
                sort,
                setSort,
                activeStep,
                setActiveStep,
                myProfile,
                setMyProfile,
                paymentDetail,
                setPaymentDetail,
                offset,
                setOffset,
                shoppingFee,
                setShoppingFee,
                deliveryFee,
                setDeliveryFee,
                totalAmount,
                memo,
                setMemo,
                cancel,
                setCancel,
                deliveryDay,
                setDeliveryDay,
                token,
                orderNumber,
                setOrderNumber,
                email,
                setEmail,
                tip,
                setTip,
                couponPrice,
                setCouponPrice,
                isCouponApplied,
                setIsCouponApplied,
                couponCode,
                setCouponCode,
                japaneseSearchQuery,
                setJapaneseSearchQuery,
                englishSearchQuery,
                setEnglishSearchQuery,
                isNoFee,
                loginDialogOpen,
                setLoginDialogOpen,
                isFirstTime,
                setIsFirstTime,
                isLoading,
                setIsLoading,
            }}
        >
            {!isNoFee && isFirstTime && token && activeStep === 0 &&
                <div style={{
                    backgroundColor: '#FFF8E1',
                    textAlign: 'center',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    fontSize: '15px',
                }}>
                    {isEnglish ? '＼ No fee on first order ／' : '＼ 初回注文は手数料無料 ／'}
                </div>}
            {!token && activeStep === 0 &&
                <div style={{
                    backgroundColor: '#FFF8E1',
                    textAlign: 'center',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    fontSize: '15px',
                }}>
                    {isEnglish ? '＼ No fee on first order ／' : '＼ 初回注文は手数料無料 ／'}
                </div>
            }
            {!isFirstTime && token && activeStep === 0 &&
                <div style={{
                    backgroundColor: '#FFF8E1',
                    textAlign: 'center',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    fontSize: '15px',
                }}>
                    {isEnglish ? '＼ No fee for over ¥20,000 ／' : '＼ 20,000円以上で手数料無料 ／'}
                </div>
            }
            <Container maxWidth='lg' style={{paddingLeft: '8px', paddingRight: '8px'}}>
                {activeStep === 0 ?
                    <div className={classes.headerFixed}>
                        {activeStep === 0 &&
                            <Grid container justify='center' style={{paddingTop: '13px'}}>
                                <Typography
                                    variant='h6'
                                    className={classes.place}>
                                    ¥
                                    <span id='totalAmount'>
                {totalAmount}
                    </span>
                                </Typography>
                                <div style={{
                                    color: 'black',
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    whiteSpace: 'pre-wrap',
                                    marginLeft: '1rem',
                                }}>
                                    {isEnglish ?
                                        '\n\n Go to cart  →' :
                                        '\n\n カートへ進む  →'
                                    }
                                </div>
                                {/*}*/}

                                <IconButton
                                    onClick={cartIconClick}
                                    disabled={!activateCart}
                                    className={classes.cartIcon}>
                                    <Badge badgeContent={calculateCartLength()} color="primary">
                                        <ShoppingCartOutlinedIcon/>
                                    </Badge>
                                </IconButton>
                            </Grid>
                        }
                    </div>
                    :
                    <div className={classes.header}>
                        <div style={{textAlign: 'center'}}>
                            {/*cart*/}
                            {activeStep === 1 &&
                                <img src={isEnglish ? cart_title_eng : cart_title_jp}
                                     alt="title"
                                     className={classes.titleImg}/>
                                // <h1 className={classes.orangeTitle}>
                                //     {isEnglish ? 'Cart': 'カート'}
                                // </h1>
                            }

                            {/*checkout*/}
                            {2 <= activeStep && activeStep <= 4 &&
                                <img src={isEnglish ? co_title_eng : co_title_jp} alt="title"
                                     className={classes.titleImg}/>
                            }

                            {/*confirm*/}
                            {(activeStep === 5 || activeStep === 10 || activeStep === 7) &&
                                <img src={isEnglish ? confirm_title_eng : confirm_title_jp}
                                     alt="title"
                                     className={classes.titleImg}/>
                            }

                            {/*thanks*/}
                            {activeStep === 6 &&
                                (isLoading ?
                                    <img src={isEnglish ? ordering_title_eng : ordering_title_jp}
                                         alt="title"
                                         className={classes.titleImg}/> :
                                    <img src={isEnglish ? order_done_title_eng : order_done_title_jp}
                                         alt="title"
                                         className={classes.titleImg}/>)
                            }
                        </div>

                        {/*カート*/}
                        {activeStep === 1 &&
                            <div>
                                <Button
                                    onClick={() => {
                                        setActiveStep(activeStep - 1);
                                        setCouponPrice(0);
                                        setCouponCode('');
                                        setIsCouponApplied(false);
                                    }}>{isEnglish ? 'Back' : '戻る'}
                                </Button>

                                {/*カートリセット*/}
                                {cart.length !== 0 &&
                                    <Button
                                        style={{float: 'right'}}
                                        onClick={() => setConfirmDialogOpen(true)}>{isEnglish ? 'Reset' : 'リセット'}
                                    </Button>
                                }
                                <Dialog
                                    open={confirmDialogOpen}
                                    onClose={() => setConfirmDialogOpen(false)}
                                >
                                    <DialogTitle>{isEnglish ? 'Are you sure you want to delete it?' : '本当に削除しますか？'}</DialogTitle>

                                    <DialogActions style={{justifyContent: 'center'}}>
                                        <Button
                                            onClick={() => {
                                                setCart([]);
                                                setConfirmDialogOpen(false);
                                                setCouponPrice(0);
                                                setCouponCode('');
                                                setIsCouponApplied(false);
                                            }}
                                            style={{margin: '1rem'}}
                                            color="default"
                                        >
                                            {isEnglish ? 'YES' : 'はい'}
                                        </Button>

                                        <Button
                                            onClick={() => setConfirmDialogOpen(false)}
                                            style={{margin: '1rem'}}
                                            color="default"
                                        >
                                            {isEnglish ? 'NO' : 'いいえ'}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        }

                        {/*チェックアウト*/}
                        {2 <= activeStep && activeStep <= 5 &&
                            <Button onClick={() => setActiveStep(activeStep - 1)}>{isEnglish ? 'Back' : '戻る'}</Button>}

                        {/*配達時クレジット*/}
                        {activeStep === 7 &&
                            <Button onClick={() => setActiveStep(activeStep - 3)}>{isEnglish ? 'Back' : '戻る'}</Button>}

                        {/*thanks*/}
                        {activeStep === 6 &&
                            <Button
                                onClick={() => window.location.href = isEnglish ? '/e' : '/'}>
                                {isEnglish ? 'Back Home' : 'ホームに戻る'}
                            </Button>}


                        {/*クレカ確認画面*/}
                        {activeStep === 10 &&
                            <Button
                                onClick={() => window.location.href = isEnglish ? 'check_out_eng.html' : 'check_out.html'}>{isEnglish ? 'Back' : '戻る'}</Button>}
                    </div>
                }

                {activeStep === 0 && <Products/>}

                {activeStep === 1 && <CostcoCart/>}

                {2 <= activeStep && activeStep <= 8 &&
                    <CostcoCheckout activeStep={activeStep} setActiveStep={setActiveStep}/>}

                {activeStep === 10 && <CostcoConfirm urlParam={urlParam}/>}

                {isCartLink && <CartLink/>}


                <Dialog
                    open={loginDialogOpen}
                    onClose={handleClose}
                    aria-labelledby="login-alert"
                    aria-describedby="login-alert-description"
                >
                    <div style={{border: '7px solid #afeeee'}}>
                        <IconButton onClick={() => handleClose()} className={classes.closeButton}>
                            <CloseIcon/>
                        </IconButton>
                        <DialogTitle id="login-alert">
                            {isEnglish ?
                                'Sign up' :
                                'サインアップ'
                            }
                        </DialogTitle>
                        <DialogContent style={{padding: '8px 12px', whiteSpace: 'pre-wrap', textAlign: 'center'}}>
                            <DialogContentText id="login-alert-description">
                                {isEnglish ?
                                    'To continue operation\nYou need to log in.' :
                                    '操作を続けるには、\nログインが必要です。'
                                }
                            </DialogContentText>
                            <div style={{textAlign: 'center'}}>
                                <img src={logoutIcon} alt="under-construction" className={classes.dialogImage}
                                     style={{marginTop: '0', marginBottom: '0'}}
                                />
                            </div>
                            <DialogActions style={{justifyContent: 'space-between'}}>
                                <Button
                                    onClick={() => window.location.href = isEnglish ? '/register_e' : '/register'}
                                    color="default"
                                    variant='contained'>
                                    {isEnglish ? 'Register' : '新規会員登録'}
                                </Button>
                                <p style={{opacity: '0.54'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                <Button
                                    onClick={() => window.location.href = isEnglish ? '/login_costco_e' : '/login_costco'}
                                    color="default"
                                    variant='contained'>
                                    {isEnglish ? 'Login' : 'ログイン'}
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </div>
                </Dialog>
            </Container>
        </CostcoContext.Provider>
    )
}

export default withCookies(CostcoHome)