import React, {useContext} from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CostcoContext} from "../CostcoHome";

const useStyles = makeStyles((theme) => ({
    buttonArea: {
        position: 'relative',
    },
    img: {
        position: 'absolute',
        top: '59px',
        left: '64px',
        [theme.breakpoints.only('xs')]: {
            top: '59px',
            left: '22px',
        },
    },
    chooseButton: {
        paddingBottom: '90px',
        width: '90px',
        height: '150px',
        fontSize: '10px',
    },
}))


const CostcoChoosePayment = (props) => {
    const classes = useStyles()
    const currentUrl = window.location.href
    const isEnglish = currentUrl.slice(-2) === '_e'
    const {activeStep, setActiveStep} = props
    const {totalAmount, tip, couponPrice} = useContext(CostcoContext)
    const bear_img = 'images/cart_bear-min.png'
    const piyo_img = 'images/cart_piyo-min.png'
    const husky_img = 'images/cart_husky-min.png'

    const fullPrice = totalAmount + 500 + (Math.floor(totalAmount * tip * 0.001) * 10) - couponPrice
    // background: linear-gradient(transparent 70%,#FFF9C4 0%);
    return (
        <>
            {/*<p style={{textAlign: 'center'}}>*/}
            {/*    /!*{!isEnglish && '＼'}*!/*/}
            {/*    <span style={{fontWeight: 'bold', textDecoration: 'underline', textDecorationColor: 'yellow'}}>*/}
            {/*        /!*{isEnglish ? 'Now, choose cash on delivery, discount of 5% of the total product price! Can also be used in conjunction with coupons.' : '今だけ代金引換を選択すると、商品代金の合計5％分が割引に！クーポンとの併用も可能！'}*!/*/}
            {/*        {isEnglish ? 'Credit card payment is under maintenance. We apologize for any inconvenience caused.' : 'クレジット払いは定期メンテナンス中です。ご迷惑おかけして申し訳ございません。'}*/}
            {/*    </span>*/}
            {/*    /!*{!isEnglish && '／'}*!/*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*    <span style={{fontWeight: 'bold', textDecoration: 'underline', textDecorationColor: 'yellow', color: "red"}}>*/}
            {/*        {isEnglish ? 'All items are now on sale at 10% off. Only cash on delivery (COD) is accepted as the payment method during the applicable period.' : ' ※全商品10%OFFセール開催中です。対象期間中のお支払い方法は、代金引換のみとなります。'}*/}
            {/*    </span>*/}
            {/*</p>*/}
            <Grid container spacing={1} style={{textAlign: 'center'}}>
                <Grid item xs={4}>
                    {fullPrice < 0 ?
                        <div className={classes.buttonArea}
                             onClick={() => window.location.href = isEnglish ? 'check_out_eng.html' : 'check_out.html'}
                        >
                            <Button
                                disabled={fullPrice < 0}
                                className={classes.chooseButton}
                                variant="contained"
                                color="primary"
                            >
                                {isEnglish ? 'Credit card' : 'クレジット'}
                            </Button>

                            <div className={classes.img}>
                                <img src={husky_img}
                                     width="52px"
                                     height='80px'
                                     alt='bear'
                                />
                            </div>
                        </div>
                        :
                        <div className={classes.buttonArea}
                             onClick={() => window.location.href = isEnglish ? 'check_out_eng.html' : 'check_out.html'}
                        >
                            <Button
                                // disabled
                                className={classes.chooseButton}
                                variant="contained"
                                color="primary"
                            >
                                {isEnglish ? 'Credit card' : 'クレジット'}
                            </Button>

                            <div className={classes.img}>
                                <img src={husky_img}
                                     width="52px"
                                     height='80px'
                                     alt='bear'
                                />
                            </div>
                        </div>
                    }


                </Grid>

                <Grid item xs={4}>

                    <div className={classes.buttonArea} onClick={() => {
                        document.body.scrollTop = document.documentElement.scrollTop = 70;
                        setActiveStep(activeStep + 1);
                    }}>
                        <Button
                            className={classes.chooseButton}
                            variant="contained"
                            color="primary"
                        >
                            {isEnglish ? 'Cash on delivery' : '代金引換'}
                        </Button>
                        <div className={classes.img}>
                            <img src={piyo_img}
                                 width="52px"
                                 height='80px'
                                 alt='piyo'
                            />
                        </div>

                    </div>
                </Grid>

                <Grid item xs={4}>

                    <div className={classes.buttonArea} onClick={() => {
                        document.body.scrollTop = document.documentElement.scrollTop = 70;
                        setActiveStep(activeStep + 3);
                    }}>
                        <Button
                            className={classes.chooseButton}
                            variant="contained"
                            color="primary"
                            style={{whiteSpace: 'pre-wrap'}}
                            // disabled
                        >
                            {isEnglish ? 'Credit on delivery' : '配達時\nクレジット'}
                        </Button>

                        <div className={classes.img}>
                            <img src={bear_img}
                                 width="52px"
                                 height='80px'
                                 alt='husky'
                            />
                        </div>

                    </div>
                </Grid>

                <a href={isEnglish ? '/credit_on_delivery_e' : '/credit_on_delivery'}
                   style={{color: 'blue', marginLeft: '7px', marginTop: '1rem', textDecoration: 'none'}}
                   target='_blank' rel="noopener noreferrer"
                >
                    {isEnglish ? 'What is Credit on delivery？' : '配達時クレジット払いとは？'}
                </a>
                {/*<p style={{color: 'red', textAlign: 'left', marginLeft: '7px'}}>*/}
                {/*    {isEnglish ?*/}
                {/*        'Credit card and QR payment systems are currently under maintenance. We are now accepting orders only by cash on delivery. We will return to normal as soon as the maintenance is completed.':*/}
                {/*        '※現在クレジット決済、各種QR決済のメンテナンス中です。つきまして、代金引換による注文のみ承ってます。メンテナンス終了次第、通常通りに戻させていただきます。'*/}
                {/*    }*/}
                {/*</p>*/}
            </Grid>
            <p style={{
                textAlign: 'left',
                marginLeft: '7px',
                // color: 'darkgray',
            }}>{isEnglish ? '→ PayPay is also available' : '→ PayPayも対応可能'}</p>


        </>
    )
}

export default CostcoChoosePayment