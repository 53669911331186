import React, {useContext, useEffect, useReducer, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {isValidCombination} from "../utils/func";
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {REGISTER} from "../../Messages/constantMessages";
import axios from "axios";
import {
    AUTHEN_URL, COSTCO_INVITATION_URL, COSTCO_PERSONAL_COUPON_URL,
    CREATE_PROFILE_URL,
    CREATE_USER_URL,
    REGISTER_MAIL_URL,
} from "../../Messages/urls";
import loginReducer from "../../reducers/loginReducer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {FETCH_SUCCESS, INPUT_EDIT, START_FETCH} from "../../Messages/actions";
import {RegisterContext} from "./RegisterTop";
import {withCookies} from "react-cookie";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2.4, 0, 2),
    },
    span: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'teal',
    },
    spanError: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'fuchsia',
        marginTop: 10,
    },
    margin: {
        margin: theme.spacing(1),
    },
    titleImg: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    errorMessage: {
        color: '#ff5722',
    },
    warningStyles: {
        "& .MuiFormLabel-root.Mui-error": {
            color: "#ff5722 !important",
        },
        "& .MuiOutlinedInput-root.Mui-error": {
            '& fieldset': {
                borderColor: '#ff5722',
            },
        },
    },
    warningStylesLabel: {
        "&.Mui-error": {
            color: "#ff5722"
        }
    },
    warningStylesOutlinedInput: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: '#ff5722 !important',
        },
    },
    selectForm: {
        width: '100%',
        "& .MuiFormLabel-root.Mui-error": {
            color: "#ff5722 !important",
        },
        "& .MuiOutlinedInput-root.Mui-error": {
            '& fieldset': {
                borderColor: '#ff5722',
            },
        },
    }
}))


const ProfileForm = (props) => {

    const {isEnglish, email, invitationCode, setInvitationCode} = useContext(RegisterContext)
    const classes = useStyles()

    const initialState = {
        isLoading: false,
        isLoginView: true,
        error: '',
        credentialsReg: {
            email: email,
            password: ''
        },
        credentialsProf: {
            first_name: '',
            last_name: '',
            postal_code: '',
            prefecture: '',
            city_name: '',
            place_number: '',
            building_floor_room_name: '',
            phone_number: '',
        }
    };
    const [state, dispatch] = useReducer(loginReducer, initialState)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const isMatchPassword = state.credentialsReg.password === confirmPassword
    const [checkAgree, setCheckAgree] = useState(false)
    const [isRegisterButtonClicked, setIsRegisterButtonClicked] = useState(false)
    const isExistName = !state.credentialsProf.first_name || !state.credentialsProf.last_name
    const isFillAddress = !state.credentialsProf.city_name || !state.credentialsProf.place_number
    const isFillProf = !state.credentialsProf.phone_number || isFillAddress
    const isFill = !state.credentialsReg.password || !state.credentialsReg.email
    const isLongPassword = state.credentialsReg.password.length > 5
    const isValidArea = isValidCombination(state.credentialsProf.prefecture, state.credentialsProf.city_name)
    const unCreatable = isFill || !isMatchPassword || !checkAgree || isExistName || isRegisterButtonClicked || !isLongPassword || isFillProf || !isValidArea

    const tokyo = isEnglish ? 'Tokyo' : '東京都'
    const kanagawa = isEnglish ? 'Kanagawa' : '神奈川県'


    const inputChangedReg = () => event => {
        const cred = state.credentialsReg;
        cred[event.target.name] = event.target.value;
        dispatch({
            type: INPUT_EDIT,
            inputName: 'state.credentialReg',
            payload: cred,
        })
    }
    const inputChangedProf = () => event => {
        const cred = state.credentialsProf;
        cred[event.target.name] = event.target.value;
        dispatch({
            type: INPUT_EDIT,
            inputName: 'state.credentialsProf',
            payload: cred,
        })
    }

    const inputChangeConfirmPassword = () => event => {
        setConfirmPassword(event.target.value)
    }


    const sendRegisterMail = async (profileId) => {

        const registerURL = isEnglish ? `${REGISTER_MAIL_URL}${profileId}/eng` : `${REGISTER_MAIL_URL}${profileId}/jp`
        await axios.get(registerURL)
    }

    const getRandomStr = () => {
        const LENGTH = 6 //生成したい文字列の長さ
        const SOURCE = "abcdefghijklmnopqrstuvwxyz0123456789" //元になる文字
        let result = ''

        for (let i = 0; i < LENGTH; i++) {
            result += SOURCE[Math.floor(Math.random() * SOURCE.length)];
        }
        return result //p9zh1z
    }


    const createInvitation = async (userId) => {
        const data = {
            "invite": userId,
            "invitee_list": [2],
            "used_user_list": [2],
            "code": getRandomStr()
        }
        await axios.post(COSTCO_INVITATION_URL, data, {
            headers: {'Content-Type': 'application/json'}
        })
    }

    const activateRegisterButton = () => {
        setIsRegisterButtonClicked(false)
    }

    const register = async (event) => {
        event.preventDefault()
        dispatch({type: START_FETCH})
        setIsRegisterButtonClicked(true)
        setTimeout(activateRegisterButton, 5000)
        const credentialsLog = {
            username: state.credentialsReg.email,
            password: state.credentialsReg.password,
        }
        // アカウント作成　→　ログイン → トークン取得 → プロフィール作成
        // アカウント作成
        await axios.post(CREATE_USER_URL, state.credentialsReg, {
            headers: {'Content-Type': 'application/json'}
        })
        // ログイン
        const res = await axios.post(AUTHEN_URL, credentialsLog, {
            headers: {'Content-Type': 'application/json'}
        })
        props.cookies.set('current-token', res.data.token)
        // トークン取得
        const token = res.data.token

        // プロフィール作成
        const profileResponse = await axios.post(CREATE_PROFILE_URL, state.credentialsProf, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })

        // mailListに追加
        // editMailList(profileResponse.data.user_profile)

        // 招待モデル作成
        createInvitation(profileResponse.data.user_profile)

        // 招待クーポンが適用されているとき
        if (isInvitationApplied) {
            //    invitee_listに入れる
            updateInvitation(profileResponse.data.user_profile)
            createPersonalCoupon(profileResponse.data.user_profile)
        }

        // 新規登録メール送信
        sendRegisterMail(profileResponse.data.id)
        shiftTopPage()
        dispatch({type: FETCH_SUCCESS})
    }

    const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

    const shiftTopPage = async () => {
        await sleep(1000);
        await isEnglish ? window.location.href = "/costco_e" : window.location.href = "/costco"
    }

    const handleChangeAgree = () => {
        setCheckAgree(!checkAgree)
    }

    const [isInvitationApplied, setIsInvitationApplied] = useState(false)
    const [invitationMessage, setInvitationMessage] = useState('')

    const handleInvitationCode = () => event => {
        const value = event.target.value
        setInvitationCode(value)
    }

    const getInvitationCode = async () => {
        if (invitationCode.length > 5) {
            const invitation = await axios.get(`${COSTCO_INVITATION_URL}?code=${invitationCode}`)
            const invitationData = invitation.data[0]
            if (!invitationData) {
                setInvitationMessage(isEnglish ? 'Invalid code' : '無効なコードです')
            } else {
                setInvitationMessage('')
                setIsInvitationApplied(true)
                setInvitationCode(invitationCode)
            }
        } else {
            setInvitationMessage(isEnglish ? 'Invalid code' : '無効なコードです')
        }
    }

    const updateInvitation = async (userId) => {
        const invitationResponse = await axios.get(`${COSTCO_INVITATION_URL}?code=${invitationCode}`)
        const invitationData = invitationResponse.data[0]
        const inviteeArray = invitationData.invitee_list
        inviteeArray.push(userId)
        const updatedData = {
            "invite": invitationData.invite,
            "invitee_list": inviteeArray,
            "used_user_list": invitationData.used_user_list,
            "code": invitationData.code
        }
        await axios.put(`${COSTCO_INVITATION_URL}${invitationData.id}/`, updatedData, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    const createPersonalCoupon = async (userId) => {
        // 招待者にクーポンを発行
        const data = {
            "code": getRandomStr(),
            "is_used": false,
            "user": userId,
            // "discounted_price": 1000
            "discounted_price": 500
        }
        await axios.post(COSTCO_PERSONAL_COUPON_URL, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }

    const attachClassNameOutlinePassword = () => {
        if (!state.credentialsReg.password || (state.credentialsReg.password && !isLongPassword)) {
            return classes.warningStylesOutlinedInput
        } else {
            return ''
        }
    }
    const attachClassNameOutlineConfirmPassword = () => {
        if ((!confirmPassword || !isMatchPassword)) {
            return classes.warningStylesOutlinedInput
        } else {
            return ''
        }
    }

    useEffect(() => {
        if (invitationCode) {
            getInvitationCode()
        }
    }, [invitationCode])


    return (
        <div>
            <form onSubmit={register}>
                <div className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.warningStyles}
                                variant="outlined" margin="normal"
                                fullWidth
                                placeholder={isEnglish ? 'Yamada' : '山田'}
                                label={isEnglish ? 'Last Name' : '苗字'}
                                name="last_name"
                                value={state.credentialsProf.last_name}
                                onChange={inputChangedProf()}
                                {...(!state.credentialsProf.last_name ? {error: true} : {error: false})}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                className={classes.warningStyles}
                                variant="outlined" margin="normal"
                                fullWidth
                                placeholder={isEnglish ? 'Taro' : '太郎'}
                                label={isEnglish ? 'First Name' : '名前'}
                                name="first_name"
                                value={state.credentialsProf.first_name}
                                onChange={inputChangedProf()}
                                {...(!state.credentialsProf.first_name ? {error: true} : {error: false})}
                            />
                        </Grid>
                    </Grid>

                    <TextField
                        className={classes.warningStyles}
                        variant="outlined" margin="normal"
                        fullWidth
                        placeholder='1234567'
                        label={isEnglish ? 'Postal Code (no hyphen)' : '郵便番号(ハイフンなし)'}
                        name="postal_code"
                        value={state.credentialsProf.postal_code}
                        onChange={inputChangedProf()}
                    />

                    <FormControl variant="outlined" className={classes.selectForm} margin="normal"
                                 {...(!state.credentialsProf.prefecture ? {error: true} : {error: false})}>
                        <InputLabel
                            id="prefecture-simple-select-outlined-label">{isEnglish ? 'Prefecture' : '都道府県'}</InputLabel>
                        <Select
                            labelId="prefecture-simple-select-outlined-label"
                            id="prefecture-simple-select-outlined"
                            value={state.credentialsProf.prefecture}
                            onChange={inputChangedProf()}
                            label={isEnglish ? 'Prefecture' : '都道府県'}
                            name="prefecture"
                        >
                            <MenuItem value={tokyo}>{tokyo}</MenuItem>
                            <MenuItem value={kanagawa}>{kanagawa}</MenuItem>
                        </Select>
                    </FormControl>

                    {state.credentialsProf.prefecture === tokyo &&
                    <FormControl variant="outlined" className={classes.selectForm} margin="normal"
                                 {...(!isValidCombination(state.credentialsProf.prefecture, state.credentialsProf.city_name) ? {error: true} : {error: false})}>
                        <InputLabel
                            id="city-simple-select-outlined-label">{isEnglish ? 'City Name' : '市区町村名'}</InputLabel>
                        <Select
                            labelId="city-simple-select-outlined-label"
                            id="city-simple-select-outlined"
                            value={state.credentialsProf.city_name}
                            onChange={inputChangedProf()}
                            label={isEnglish ? 'City Name' : '市区町村名'}
                            name="city_name"
                            margin="normal"
                        >
                            <MenuItem value={isEnglish ? 'Shinjuku' : '新宿区'}>{isEnglish ? 'Shinjuku' : '新宿区'}</MenuItem>
                            <MenuItem value={isEnglish ? 'Chiyoda' : '千代田区'}>{isEnglish ? 'Chiyoda' : '千代田区'}</MenuItem>
                            <MenuItem value={isEnglish ? 'Chuo' : '中央区'}>{isEnglish ? 'Chuo' : '中央区'}</MenuItem>
                            <MenuItem value={isEnglish ? 'Koto' : '江東区'}>{isEnglish ? 'Koto' : '江東区'}</MenuItem>
                            <MenuItem value={isEnglish ? 'Minato' : '港区'}>{isEnglish ? 'Minato' : '港区'}</MenuItem>
                            <MenuItem value={isEnglish ? 'Shibuya' : '渋谷区'}>{isEnglish ? 'Shibuya' : '渋谷区'}</MenuItem>
                            <MenuItem
                                value={isEnglish ? 'Shinagawa' : '品川区'}>{isEnglish ? 'Shinagawa' : '品川区'}</MenuItem>
                            <MenuItem value={isEnglish ? 'Meguro' : '目黒区'}>{isEnglish ? 'Meguro' : '目黒区'}</MenuItem>
                            <MenuItem
                                value={isEnglish ? 'Setagaya' : '世田谷区'}>{isEnglish ? 'Setagaya' : '世田谷区'}</MenuItem>
                            <MenuItem value={isEnglish ? 'Ota' : '大田区'}>{isEnglish ? 'Ota' : '大田区'}</MenuItem>
                        </Select>
                    </FormControl>}


                    {state.credentialsProf.prefecture === kanagawa &&
                    <FormControl variant="outlined" className={classes.selectForm} margin="normal"
                                 {...(!isValidCombination(state.credentialsProf.prefecture, state.credentialsProf.city_name) ? {error: true} : {error: false})}>
                        <InputLabel
                            id="city-simple-select-outlined-label">{isEnglish ? 'City Name' : '市区町村名'}</InputLabel>
                        <Select
                            labelId="city-simple-select-outlined-label"
                            id="city-simple-select-outlined"
                            value={state.credentialsProf.city_name}
                            onChange={inputChangedProf()}
                            label={isEnglish ? 'City Name' : '市区町村名'}
                            name="city_name"
                            margin="normal"
                        >
                            <MenuItem
                                value={isEnglish ? 'Kawasaki-shi Kawasaki' : '川崎市 川崎区'}>{isEnglish ? 'Kawasaki-shi Kawasaki' : '川崎市 川崎区'}</MenuItem>
                            <MenuItem
                                value={isEnglish ? 'Kawasaki-shi Saiwai' : '川崎市 幸区'}>{isEnglish ? 'Kawasaki-shi Saiwai' : '川崎市 幸区'}</MenuItem>
                            <MenuItem
                                value={isEnglish ? 'Kawasaki-shi Nakahara' : '川崎市 中原区'}>{isEnglish ? 'Kawasaki-shi Nakahara' : '川崎市 中原区'}</MenuItem>
                            <MenuItem
                                value={isEnglish ? 'Yokohama-shi Kohoku' : '横浜市 港北区'}>{isEnglish ? 'Yokohama-shi Kohoku' : '横浜市 港北区'}</MenuItem>
                            <MenuItem
                                value={isEnglish ? 'Yokohama-shi Tsurumi' : '横浜市 鶴見区'}>{isEnglish ? 'Yokohama-shi Tsurumi' : '横浜市 鶴見区'}</MenuItem>
                        </Select>
                    </FormControl>}

                    <TextField
                        className={classes.warningStyles}
                        variant="outlined" margin="normal"
                        fullWidth
                        placeholder={isEnglish ? 'Roppongi 1-1-1' : '六本木 1-1-1'}
                        label={isEnglish ? 'Place Name and Address' : '地名・番地'}
                        name="place_number"
                        value={state.credentialsProf.place_number}
                        onChange={inputChangedProf()}
                        {...(!state.credentialsProf.place_number ? {error: true} : {error: false})}
                    />
                    <TextField
                        className={classes.warningStyles}
                        variant="outlined" margin="normal"
                        fullWidth
                        placeholder={isEnglish ? 'Huskyhills201' : 'ハスキーヒルズ201'}
                        label={isEnglish ? 'Building name, floor and room number' : '建物名・階・部屋番号'}
                        name="building_floor_room_name"
                        value={state.credentialsProf.building_floor_room_name}
                        onChange={inputChangedProf()}
                    />

                    <TextField
                        className={classes.warningStyles}
                        variant="outlined" margin="normal"
                        fullWidth
                        placeholder='08012345678'
                        label={isEnglish ? 'Phone Number (no hyphen)' : '電話番号(ハイフンなし)'}
                        name="phone_number"
                        value={state.credentialsProf.phone_number}
                        onChange={inputChangedProf()}
                        {...(!state.credentialsProf.phone_number ? {error: true} : {error: false})}
                    />
                    <TextField
                        disabled
                        className={classes.warningStyles}
                        variant="outlined" margin="normal"
                        fullWidth
                        placeholder='support@husky-cart.com'
                        label={isEnglish ? 'Email' : 'メールアドレス'}
                        name="email"
                        value={state.credentialsReg.email}
                        onChange={inputChangedReg()}
                        {...(!state.credentialsReg.email ? {error: true} : {error: false})}
                    />

                    <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        fullWidth
                    >
                        <InputLabel
                            className={classes.warningStylesLabel}
                            htmlFor="outlined-adornment-password"
                            {...(!state.credentialsReg.password || (state.credentialsReg.password && !isLongPassword) ? {error: true} : {error: false})}>
                            {isEnglish ? 'Password' : 'パスワード'}
                        </InputLabel>
                        <OutlinedInput
                            className={attachClassNameOutlinePassword()}
                            id="outlined-adornment-password"
                            name="password"
                            label={isEnglish ? 'Password' : 'パスワード'}
                            type={showPassword ? 'text' : 'password'}
                            value={state.credentialsReg.password}
                            onChange={inputChangedReg()}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            {...(!state.credentialsReg.password || (state.credentialsReg.password && !isLongPassword) ? {error: true} : {error: false})}
                        />
                        {state.credentialsReg.password && !isLongPassword &&
                        <span
                            className={classes.errorMessage}>{isEnglish ? 'Password is too short' : 'パスワードが短すぎます'}</span>}
                    </FormControl>

                    <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        fullWidth
                    >
                        <InputLabel
                            className={classes.warningStylesLabel}
                            htmlFor="outlined-adornment-confirm-password"
                            {...(!confirmPassword || !isMatchPassword ? {error: true} : {error: false})}>
                            {isEnglish ? 'Confirm Password' : '確認用パスワード'}
                        </InputLabel>
                        <OutlinedInput
                            className={attachClassNameOutlineConfirmPassword()}
                            id="outlined-adornment-confirm-password"
                            label={isEnglish ? 'Confirm Password' : '確認用パスワード'}
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={confirmPassword}
                            onChange={inputChangeConfirmPassword()}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            {...(!confirmPassword || !isMatchPassword ? {error: true} : {error: false})}
                        />
                        {!isMatchPassword &&
                        <span
                            className={classes.errorMessage}>{isEnglish ? 'Password doesn\'t match.' : 'パスワードが一致していません'}</span>}

                    </FormControl>

                    <div style={{marginTop: '1rem'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkAgree}
                                    onChange={handleChangeAgree}
                                    color="primary"
                                />
                            }
                            label={
                                isEnglish ?
                                    <span>I agree to the <a href="usage_contract_e"
                                                            target="_blank">Terms of Use</a></span> :
                                    <span><a href="/usage_contract" target="_blank">利用規約</a>に同意する</span>
                            }
                        />
                    </div>
                    {!checkAgree &&
                    <span
                        className={classes.errorMessage}
                        style={{marginBottom: '1rem'}}>{isEnglish ? 'Agreement is required for registration.' : '会員登録には同意が必要です'}</span>}

                    <Grid container>
                        <Grid item xs={8}>
                            <TextField
                                onChange={handleInvitationCode()}
                                placeholder={isEnglish ? 'Invitation code' : '招待コード'}
                                disabled={isInvitationApplied}
                                value={invitationCode}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button disabled={isInvitationApplied} variant='contained'
                                    onClick={() => getInvitationCode()}>
                                {isEnglish ? 'Apply' : '適用'}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {isInvitationApplied ?
                                <p>{isEnglish ? 'Invitation has been applied' : '招待が適用されました'}</p> :
                                <p>{invitationMessage}</p>}
                        </Grid>
                    </Grid>
                    {state.isLoading && <CircularProgress/>}
                    <Button
                        className={classes.submit}
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        disabled={unCreatable}
                    >{isEnglish ? REGISTER : '登録'}</Button>
                </div>

                {isEnglish ?
                    <div style={{marginTop: '1rem', backgroundColor: 'rgba(0, 0, 0, 0.03)', padding: '1rem'}}>
                        <p style={{margin: 0}}>
                            Depending on communication conditions, you may not be able to successfully move to the next page even if you press the registration button.
                            In that case, you may have already completed your membership registration.
                            Please try logging in from <a href="/login_e">the login page here</a> with the information you have entered.
                        </p>
                    </div>
                    :
                    <div style={{marginTop: '1rem', backgroundColor: 'rgba(0, 0, 0, 0.03)', padding: '1rem'}}>
                        <p style={{margin: 0}}>通信状況によっては登録ボタンを押しても上手くページ遷移されない場合がございます。その際はすでに会員登録が完了されている可能性があります。
                            <a href="/login">こちらのログインページ</a>より、入力いただいた内容でログインをお試しください。
                        </p>
                    </div>
                }
            </form>
        </div>
    )
}

export default withCookies(ProfileForm)